import "./App.css";
import Payment from "./Payment";
import Completion from "./Completion";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Header";
import SubscriptionModal from "./SubscriptionModal";
import { ChakraProvider } from "@chakra-ui/react";
import LoginModal from "./LoginModal";
import { useEffect, useState } from "react";

function App() {
  const [user, setUser] = useState(null)

  useEffect(()=>{
 setUser(JSON.parse(localStorage.getItem("user")))
  },[])
  
  return (
    <ChakraProvider>
      <main>
        <BrowserRouter>
          <Header setUser={setUser} user={user} />
          <Routes>
            <Route path="/" element={user?<SubscriptionModal user={user} />:<LoginModal setUser={setUser} />} />
            <Route path="/completion" element={<Completion />} />
          </Routes>
        </BrowserRouter>
      </main>
    </ChakraProvider>
  );
}

export default App;
