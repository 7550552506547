/*global chrome*/
import React, { useEffect, useState } from "react";
import {
  ShoppingCartOutlined,
  HomeOutlined,
  LoginOutlined,
  LogoutOutlined,
  PayCircleOutlined
} from "@ant-design/icons";
import LoginModal from "./LoginModal";
import { Tooltip } from "antd";
const Header = ({ user, setUser }) => {
  const [openLogin, setOpenLogin] = useState(false);
  

  
  useEffect(() => {}, []);
  return (
    <div
      style={{
        display: "flex",
        width: "90%",
        justifyContent: "space-between",
        marginBottom: "5px",
      }}
    >
      <div>
        <img style={{ width: "50px" }} src="logo.png" />
      </div>
      <div style={{ display: "flex" }}>
       
      
        <div>
          {!user ? (
            <Tooltip placement="left" title="Login" color="#f06821">
              <LoginOutlined onClick={() => setOpenLogin(true)} />{" "}
            </Tooltip>
          ) : (
            <div style={{display:"flex"}}>
             
              <Tooltip
                placement="left"
                title=" Click here to signout"
                color="#f06821"
              >
                <LogoutOutlined
                  onClick={() =>
                    {localStorage.setItem('user', null )
                    setUser(null)
                  }
                  }
                />{" "}
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
