
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"))
const backendUrl = process.env.REACT_APP_BACKEND_URL;


console.log(user, "from api")
// export const currencyAxiosInstance = axios.create({
//   baseURL: `${currencyBaseUrl}/symbols/?access_key=/${process.env.REACT_APP_API_KEY}`,

const userConfig = {
  headers: {
    "x-access-token": user?.token,
  },
};


export const login = (body) => {
  return axios.post(`${backendUrl}/user/login`, body);
};

export const signUp = (body) => {
  return axios.post(`${backendUrl}/user/signup`, body);
};

export const subscribe = (body) => {
   userConfig.headers["x-access-token"] = body.token;
   delete body.token
  return axios.post(`${backendUrl}/subscribe/subscribe`, body, userConfig);
};

export const  createIntent = (body) => {
  return axios.post(`${backendUrl}/payments/create-payment-intent`, body, userConfig);
};

export const getSublist = (token) => {
   userConfig.headers["x-access-token"] = token;
  return axios.get(
    `${backendUrl}/payments/sublist`,
    userConfig
  );
};

export const getConfig = (token) => {
   userConfig.headers["x-access-token"] = token;
  return axios.get(`${backendUrl}/payments/config`, userConfig);
};


export const getActiveub = (userId, token) => {
  userConfig.headers["x-access-token"]=token
  return axios.get(
    `${backendUrl}/subscribe/active?userId=${userId}`,
    userConfig
  );
};

export const updateCount = (body) => {
  return axios.post(`${backendUrl}/subscribe/updateusage`, body, userConfig);
};

export const getAllSubs = (userId, token) => {
   userConfig.headers["x-access-token"] = token;
  return axios.get(
    `${backendUrl}/subscribe/subscrptions?userId=${userId}`,
    userConfig
  );
};

