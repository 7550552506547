import React, { useState } from "react";
import { Button, Modal, Input, Space } from "antd";
import { EyeTwoTone } from "@ant-design/icons";
import { login, signUp } from "./api";

const LoginModal = ({ openLogin, setOpenLogin, setUser }) => {
  const [state, setState] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const handleLogin = (details) => {
    try {
      setConfirmLoading(true);

      login(details)
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.data.data));
          setUser(res.data.data);
          console.log(res.data.data);

          setConfirmLoading(false);
          setOpenLogin(false);
        })
        .catch((err) => {
          console.log(err.message);
          setConfirmLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ fontSize: "14px", display: "flex", flexDirection:"column", }}>
      <Space direction="vertical">
        <div
          style={{
            fontSize: "14px",
            display: "flex",
            flexDirection: "column",
            
          }}
        >
          <p>Email</p>
          <Input
            type="text"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="please enter your email"
          />
        </div>

        <div
          style={{ fontSize: "14px", display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "10px",
            }}
          >
            <p>password</p>
            <Input.Password
              type="text"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="input password"
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />
          </div>

          <Button
            style={{
              width: 80,
            }}
            onClick={() => setPasswordVisible((prevState) => !prevState)}
          >
            {passwordVisible ? "Hide" : "Show"}
          </Button>
        </div>
        <div
          style={{ fontSize: "12px", color: "red", cursor: "pointer" }}
          onClick={() => setState("register")}
        ></div>
      </Space>
      <Button
        loading={confirmLoading}
        onClick={() => handleLogin({ email, password })}
      >
        Submit
      </Button>
      
    </div>
  );
};

export default LoginModal;
